import * as React from "react";

import { Query } from "@graphql-types";
import BlogLandingTemplate from "@components/blogs/blogLandingTemplate";
import { graphql } from "gatsby";
import { BreadcrumbPageContext } from "@util/types";

interface Props {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const BlogLandingPage = (props: Props) => {
  const { data, pageContext } = props;
  const category = pageContext.slug;
  if (category == null) return null;

  return (
    <BlogLandingTemplate
      hero={data.allSanityBlogLandingPage?.nodes[0]?.hero}
      selectedBlogs={data.allSanityBlog.nodes}
      selectedCategory={category}
      pageContext={pageContext}
      allSanityBlog={data.allSanityBlog}
      allSanityBlogCategory={data.allSanityBlogCategory}
    />
  );
};

export default BlogLandingPage;

export const query = graphql`
  query BlogsLandingQuery($iban: String, $slug: String) {
    allSanityBlogLandingPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
      }
    }
    allSanityBlog(
      sort: { fields: publishDate, order: DESC }
      filter: {
        regions: { elemMatch: { iban: { eq: $iban } } }
        category: { main: { slug: { current: { eq: $slug } } } }
      }
    ) {
      nodes {
        ...blogPreview
      }
    }
    allSanityBlogCategory(
      sort: { order: ASC, fields: order }
      filter: { main: { regions: { elemMatch: { iban: { eq: $iban } } } } }
    ) {
      nodes {
        _id
        order
        main {
          hero {
            ...sanityHero
          }
          slug {
            current
          }
          title
        }
      }
    }
  }
`;
