import * as React from "react";

import { Container, FlexGrid } from "@util/standard";
import {
  Maybe,
  SanityBlog,
  SanityBlogCategory,
  SanityBlogCategoryConnection,
  SanityBlogConnection,
  SanityHero,
  SanitySeo,
} from "@graphql-types";

import { Breadcrumb } from "@global";
import { BreadcrumbPageContext } from "@util/types";
import CategoryMenu from "@shared/categoryMenu";
import Hero from "@shared/hero";
import Layout from "@shared/layout";
import PreviewAll from "@shared/previewAll";
import RecipePreviewCarousel from "@components/recipes/recipePreviewCarousel";
import SEO from "@shared/seo";
import { pageWidthMargin } from "@util/helper";
import styled from "styled-components";
import { useCheckScreenWidth } from "@util/hooks";

interface TagGroup {
  id: Maybe<string> | undefined;
  name: Maybe<string> | undefined;
  slug: Maybe<string> | undefined;
  _key: Maybe<string> | undefined;
  blogPosts: Array<any>;
  initialOrder: number;
}

const sortBlogs = (
  allSanityBlog: SanityBlogConnection,
  allSanityBlogCategory: SanityBlogCategoryConnection
): TagGroup[] => {
  //Create blog tags
  const sortedBlogCategories: TagGroup[] = [];

  //Add all tags to array
  allSanityBlogCategory.nodes.forEach(tag => {
    let tagGroup: TagGroup = {
      id: tag._id,
      name: tag.main?.title,
      slug: tag.main?.slug?.current,
      _key: tag._key,
      blogPosts: [],
      initialOrder: tag?.order ?? 0,
    };
    sortedBlogCategories.push(tagGroup);
  });

  allSanityBlog.nodes.forEach((blog: SanityBlog) => {
    if (blog.category) {
      sortedBlogCategories.forEach((parentTag: any) => {
        if (blog.category?._id == parentTag.id) {
          parentTag.blogPosts.push(blog);
        }
      });
    }
  });

  const compareOrderValues = (a: TagGroup, b: TagGroup) => {
    if (a.initialOrder < b.initialOrder) {
      return -1;
    }
    if (a.initialOrder > b.initialOrder) {
      return 1;
    }
    return 0;
  };

  const orderedBlogs = sortedBlogCategories.sort(compareOrderValues);

  return orderedBlogs;
};

const StyledContainer = styled(Container)`
  div[class^="collectionAccordian__AccordionsContainer"] {
    width: 100%;
  }
`;

interface Props {
  hero?: Maybe<SanityHero> | undefined;
  featuredBlog?: any;
  selectedCategory?: Maybe<string> | undefined;
  selectedBlogs?: Array<SanityBlog>;
  isAllBlogs?: boolean;
  seo?: Maybe<SanitySeo> | undefined;
  slug?: string;
  pageContext: BreadcrumbPageContext;
  allSanityBlog?: SanityBlogConnection;
  allSanityBlogCategory?: SanityBlogCategoryConnection;
}

const BlogLandingTemplate = (props: Props) => {
  const {
    hero,
    selectedCategory,
    isAllBlogs,
    selectedBlogs,
    slug,
    seo,
    pageContext,
    allSanityBlog,
    allSanityBlogCategory,
  } = props;

  const sortedBlogs =
    allSanityBlog && sortBlogs(allSanityBlog, allSanityBlogCategory);

  // const selectedHero = hero ? hero : blogLandingData.hero;
  const { isMobileWidth } = useCheckScreenWidth();
  const margin = pageWidthMargin();
  return (
    <>
      <SEO seoData={seo && seo} slug={slug ? slug : "/blogs"} />
      <Layout>
        <Hero sanityHero={hero} />
        <Breadcrumb pageContext={pageContext} />

        <Container>
          <StyledContainer
            height="auto"
            width="100%"
            padding={`55px ${margin} 0`}
            display="flex"
            flexDirection="row"
          >
            <FlexGrid
              gridMargin="120px"
              laptopGridMargin="75px"
              tabletGridMargin="55px"
            >
              {isMobileWidth !== true && (
                <Container
                  width="calc(230px + 120px)"
                  laptopWidth="300px"
                  tabletWidth="200px"
                  margin="0 0 55px"
                >
                  <Container flexDirection="column">
                    <CategoryMenu
                      isBlogs
                      selectedBlogCategory={selectedCategory}
                      allSanityBlogCategory={allSanityBlogCategory}
                      isAll={isAllBlogs}
                    />
                  </Container>
                </Container>
              )}

              <Container
                width="calc(100% - 230px - 120px)"
                laptopWidth="calc(100% - 300px)"
                tabletWidth="calc(100% - 200px)"
                mobileWidth="100%"
                flexDirection="column"
              >
                <h2>{pageContext.title}</h2>
                {selectedCategory && selectedBlogs && (
                  <PreviewAll isBlogs blogs={selectedBlogs} />
                )}
                {sortedBlogs &&
                  isAllBlogs &&
                  sortedBlogs.map(category => {
                    if (!category) return;
                    return (
                      <Container key={category.id} position="relative">
                        <RecipePreviewCarousel
                          blogs={category.blogPosts.slice(0, 10)}
                          category={category}
                          showTabletNav
                          width="100%"
                        />
                      </Container>
                    );
                  })}
              </Container>
            </FlexGrid>
          </StyledContainer>
        </Container>
      </Layout>
    </>
  );
};

export default BlogLandingTemplate;
